// Crypt/Decrypt at: https://www.math.uni-hamburg.de/it/dienste/encryptma.html
export const email_data_privacy_href_mailto_crypted =
  // kshrana.dev@gmail.com
  "nbjmup;ltisbob/efwAhnbjm/dpn";

export const email_formal_href_mailto_crypted =
  // kshrana.dev@gmail.com
  "nbjmup;ltisbob/efwAhnbjm/dpn";
export const email_informal_href_mailto_crypted =
  // kshrana.dev@gmail.com
  "nbjmup;ltisbob/efwAhnbjm/dpn";
