// Mail Cryptor(s.https://www.math.uni-hamburg.de/it/dienste/encryptma.html)

export function UnCryptMailto(s) {
    var n = 0;
    var r = "";
    for (var i = 0; i < s.length; i++) {
        n = s.charCodeAt(i);
        if (n >= 8364) { n = 128; }
        r += String.fromCharCode(n - (1));
    }
    return r;
}

export function linkTo_UnCryptMailto(s) {
    window.location.href = UnCryptMailto(s);
}

export function linkTo_UnCryptMailtoWithSubjectAndBody(s, subject, body) {
    window.location.href = `${UnCryptMailto(s)}?subject=${subject}&body=${body}`;
}
