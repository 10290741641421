import React from 'react';
import './kontakt.scss';
import Contact from '../components/Contact';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

function ContactPage({ location }) {
    return (
        <Layout location={location} mainElementClassname="contact-page">
            <SEO title="Kontakt" />
            <div className="content-wrapper">
                <section>
                    <h2>Kontakt</h2>
                    <div className="line homepage-line" />
                    <Contact />
                </section>
            </div>
        </Layout>
    );
}

export default ContactPage;
