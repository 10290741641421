import React from 'react';
import './Contact.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt, faGlobeEurope, faRocket, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { email_informal_href_mailto_crypted } from '../constants';
import { linkTo_UnCryptMailto } from '../helper/unCryptMail';

function Contact() {
    return (
        <div className="contact">
            <div className="content-wrapper">
                <p>
                    Sie haben Fragen, einen Auftrag oder Ideen für gemeinsame Projekte? Hinterlassen Sie mir eine Nachricht. Ich komme schnellstmöglich auf Sie zurück.
                                </p>
                <div className="contact-details">
                    <table>
                        <tbody>
                            <tr>
                                <td><FontAwesomeIcon icon={faPhoneAlt} alt="Telefon-Nr." /></td>
                                <td className="column-data"><a href="tel:+49 721 98614757">0721/98614757</a></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faEnvelope} alt="E-Mail" /></td>
                                <td className="column-data"><button className="link" onClick={() => { linkTo_UnCryptMailto(email_informal_href_mailto_crypted); }}>kshrana.dev (at) gmail.com</button></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faGlobeEurope} alt="Webseite" /></td>
                                <td className="column-data"><a href="http://www.kishorrana.de">www.kishorrana.de</a></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faMapMarkerAlt} alt="Stadt" /></td>
                                <td className="column-data">Karlsruhe</td>
                            </tr>
                            {/* <tr>
                        <td><FontAwesomeIcon icon={faXing} /></td>
                        <td className="column-data">Kishor Rana</td>
                      </tr> */}
                        </tbody>
                    </table>
                </div>
                <div className="contact-actions">
                    <button onClick={() => { linkTo_UnCryptMailto(email_informal_href_mailto_crypted) }}><FontAwesomeIcon icon={faRocket} alt="" role="presentation" />Kontakt aufnehmen</button>
                </div>
            </div>
        </div>
    );
}

export default Contact;
